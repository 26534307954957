const REGION = "eu-west-1";

const local = {
  apiGateway: {
    REGION,
    URL: "http://localhost:4000"
  }
};

const dev = {
  apiGateway: {
    REGION,
    URL: "https://ikxpsmhdsk.execute-api.eu-west-1.amazonaws.com/dev"
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-west-1_WVP8HLLlC",
    APP_CLIENT_ID: "7hur699uv37trmqe1r986mlq69",
    IDENTITY_POOL_ID: "eu-west-1:931f426e-6ce6-480a-999e-1b24d34a83b1"
  }
};

const stag = {
  apiGateway: {
    REGION,
    URL: "https://0mwma99qod.execute-api.eu-west-1.amazonaws.com/stag"
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-west-1_93hCSSZPT",
    APP_CLIENT_ID: "440kmvpbpshjrct2q5tsh64n5p",
    IDENTITY_POOL_ID: "eu-west-1:6c290336-8463-455a-83d1-308c31d70084"
  }
};

const prod = {
  apiGateway: {
    REGION,
    URL: "https://b3w1tp63a7.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-west-1_wKzEraTVD",
    APP_CLIENT_ID: "2lepnnnuho6q10fgs22rtsuv3i",
    IDENTITY_POOL_ID: "eu-west-1:c3481dc7-c01e-4f44-81dc-51a5c42194c6"
  }
};

// Default to local if stage not set
const stage = process.env.REACT_APP_STAGE || "local";
const config =
  stage === "prod"
    ? prod
    : stage === "stag"
    ? stag
    : stage === "dev"
    ? dev
    : local;

export default {
  // Add common config values here
  stage,
  ...config
};
