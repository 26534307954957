import styled from "styled-components";

import { colors } from "constants/variables";

const InputLabel = styled.div`
  margin-bottom: 8px;
  color: ${colors.textDark};
  font-size: 16px;
`;

export default InputLabel;
