import React from "react";
import { Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "components/Routes/AuthenticatedRoute";
import UnauthenticatedRoute from "components/Routes/UnauthenticatedRoute";

import AdminLogin from "./AdminLogin";
import AdminOrders from "./AdminOrders";
// import AdminOrderEdit from "./AdminOrderEdit";
import AdminItems from "./AdminItems";
import AdminItemAdd from "./AdminItemAdd";
// import AdminItemEdit from "./AdminItemEdit";
import PageNotFound from "./PageNotFound";

export default ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute
      path="/admin"
      exact
      component={AdminLogin}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/admin/orders"
      exact
      component={AdminOrders}
      props={childProps}
    />
    {/* <AuthenticatedRoute
      path="/admin/orders/edit/:id"
      exact
      component={AdminOrderEdit}
      props={childProps}
    /> */}
    <AuthenticatedRoute
      path="/admin/items"
      exact
      component={AdminItems}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/admin/items/add"
      exact
      component={AdminItemAdd}
      props={childProps}
    />
    {/* <AuthenticatedRoute
      path="/admin/items/edit/:id"
      exact
      component={AdminItemEdit}
      props={childProps}
    /> */}
    <Route component={PageNotFound} />
  </Switch>
);
