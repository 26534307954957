import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { API } from "aws-amplify";
import { omit, pick } from "lodash";

import { getLocationDiscount } from "constants/locations";
import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  colors,
  paddingSmall
} from "constants/variables";
import { getTodaysDate, parseDate, trimPhoneNumber, scrollToTop } from "utils";

import Cover from "./Cover";
import Footer from "./Footer";
import OrderIntroduction from "./OrderIntroduction";
import OrderLocation from "./OrderLocation";
import OrderItems from "./OrderItems";
import OrderInformation from "./OrderInformation";
import OrderDone from "./OrderDone";
import Loader from "shared/Loader";

const ORDER_INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  total: 0,
  location: "Smelt",
  items: {}
};

const Order = () => {
  const [step, setStep] = useState("introduction");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [lowStockError, setLowStockError] = useState("");

  useEffect(() => scrollToTop(), [step]);

  const [order, setOrder] = useState(ORDER_INITIAL_STATE);
  const locationDiscount = getLocationDiscount(order.location);

  const handleAddItem = (item) => {
    setOrder((prevOrder) => {
      const price = Number((item.price * (1 - locationDiscount)).toFixed(2));
      return {
        ...prevOrder,
        total: prevOrder.total + price,
        items: {
          ...prevOrder.items,
          [item.id1]: {
            ...item,
            quantity: 1,
            price
          }
        }
      };
    });
  };

  const handleDeleteItem = (id) => {
    setOrder((prevOrder) => {
      const items = JSON.parse(JSON.stringify(prevOrder.items));
      const newTotal = prevOrder.total - items[id].price * items[id].quantity;
      delete items[id];
      return {
        ...prevOrder,
        total: newTotal,
        items
      };
    });
  };

  const handleItemQuantityChange = (id, quantity) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      total:
        prevOrder.total +
        prevOrder.items[id].price * (quantity - prevOrder.items[id].quantity),
      items: {
        ...prevOrder.items,
        [id]: {
          ...prevOrder.items[id],
          quantity
        }
      }
    }));
  };

  const handleSubmitOrder = async () => {
    setLoading(true);
    setError("");
    setLowStockError("");

    try {
      const items = Object.values(order.items);
      const response = await API.post("api", "/orders", {
        body: {
          ...order,
          name: order.name.trim(),
          email: order.email.trim(),
          phone: trimPhoneNumber(order.phone),
          total: Math.round(order.total * 100) / 100,
          items,
          date: getTodaysDate()
        }
      });
      order["number"] = response.number;

      setStep("done");
    } catch (error) {
      switch (error.response.data.status) {
        case "email-error":
          setError("Prišlo je do napake pri pošiljanju e-mail sporočila.");
          break;
        case "low-stock-error":
          handleLowStockItemsError(error.response.data.ids);
          break;
        case "dynamodb-error":
          setError("Prišlo je do napake na strežniku.");
          break;
        default:
          setError("Prišlo je do napake.");
      }
    }

    setLoading(false);
  };

  const handleLowStockItemsError = (ids) => {
    const itemLabels = Object.values(pick(order.items, ids)).map(
      (item) => `${item.name} (${parseDate(item.date).format("dddd")})`
    );
    setLowStockError(
      `Prišlo je do napake pri oddaji naročila. Naslednje jedi niso na voljo v zahtevanih količinah: ${itemLabels.join(
        ", "
      )}. Avtomatsko so bile odstranjene iz seznama izbranih jedi.`
    );
    setOrder((prevOrder) => ({
      ...prevOrder,
      items: omit(order.items, ids)
    }));
    setStep("items");
  };

  const handleResetOrder = () => {
    setOrder(ORDER_INITIAL_STATE);
  };

  const handleNavigationClick = (newStep) => {
    if (newStep !== step) {
      handleResetOrder();
      setStep(newStep);
    }
  };

  const steps = {
    introduction: <OrderIntroduction setStep={setStep} />,
    location: (
      <OrderLocation setStep={setStep} order={order} setOrder={setOrder} />
    ),
    items: (
      <OrderItems
        setStep={setStep}
        order={order}
        onAddItem={handleAddItem}
        onDeleteItem={handleDeleteItem}
        onItemQuantityChange={handleItemQuantityChange}
        lowStockError={lowStockError}
      />
    ),
    information: (
      <OrderInformation
        setStep={setStep}
        order={order}
        setOrder={setOrder}
        onSubmitOrder={handleSubmitOrder}
        submitOrderError={error}
      />
    ),
    done: (
      <OrderDone
        order={order}
        onResetOrder={() => {
          handleResetOrder();
          setStep("introduction");
        }}
      />
    )
  };

  return (
    <Wrapper>
      <div>
        <Cover />
        <StyledLoader isLoading={loading}>
          {() => (
            <>
              <Container>
                {step !== "introduction" && step !== "done" && (
                  <Navigation>
                    <NavigationButton
                      selected={step === "location"}
                      onClick={() => handleNavigationClick("location")}
                    >
                      1. Izberite lokacijo
                    </NavigationButton>
                    <NavigationButton
                      selected={step === "items"}
                      onClick={() => handleNavigationClick("items")}
                      disabled={step === "location"}
                    >
                      2. Izberite jedi
                    </NavigationButton>
                    <NavigationButton
                      selected={step === "information"}
                      onClick={() => handleNavigationClick("information")}
                      disabled={step === "location" || step === "items"}
                    >
                      3. Vpišite podatke
                    </NavigationButton>
                  </Navigation>
                )}
              </Container>
              {steps[step]}
            </>
          )}
        </StyledLoader>
      </div>
      <div>
        <Footer />
      </div>
    </Wrapper>
  );
};

const StyledLoader = styled(Loader)`
  transform: translateY(100px);
`;

const Wrapper = styled.div`
  padding: 18px 0;
  background-color: ${colors.black};
  color: ${colors.text};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    max-width: ${contentWidthNarrow};
  }
  text-align: center;
`;

const Navigation = styled.div`
  margin-top: ${paddingSmall};
  display: flex;
  justify-content: center;
`;

const NavigationButton = styled.button`
  text-decoration: none;
  flex: 1;
  background-color: ${colors.backgroundLight};
  border: 2px solid ${colors.backgroundLight};
  color: ${colors.textDark};
  padding: 16px 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;

  @media ${device.mobile} {
    padding: 8px 0;
    font-size: 13px;
  }

  &:not(:first-child) {
    margin-left: 16px;
  }

  &:hover {
    background-color: ${colors.backgroundExtraLight};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.backgroundLight};
      border: 2px solid ${colors.primaryDark};
      color: ${colors.primaryDark};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${colors.background};
      border: 2px solid ${colors.backgroundExtraLight};
      color: ${colors.backgroundExtraLight};
    `};
`;

export default Order;
