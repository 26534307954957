import React from "react";
import styled from "styled-components";

import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  colors
} from "constants/variables";

const PageNotFound = () => {
  return (
    <Wrapper>
      <Container>
        <Message>Ta stran ne obstaja.</Message>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 18px 0;
  color: ${colors.textDark};
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    max-width: ${contentWidthNarrow};
  }
  text-align: center;
`;

const Message = styled.div`
  margin: 25px 0;
`;

export default PageNotFound;
