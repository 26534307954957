import React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/min/locales";

import { colors, device } from "constants/variables";

import Button from "shared/Button";
import Input from "shared/Input";

moment.locale("sl");

const CartItem = ({ item, onDeleteItem, onItemQuantityChange }) => {
  const handleQuantityChange = (item, quantity) => {
    if (quantity === 0 || quantity > Math.min(item.stock, 10)) {
      return;
    }
    onItemQuantityChange(item.id1, quantity);
  };

  return (
    <Wrapper>
      <Left>
        <Category>{item.meal}</Category>
        <Name>{item.name}</Name>
      </Left>
      <Middle>
        <Quantity>
          <StyledButtonMinus
            onClick={() => handleQuantityChange(item, item.quantity - 1)}
          >
            -
          </StyledButtonMinus>
          <StyledInput
            type="number"
            min="1"
            max={item.stock}
            value={item.quantity}
            disabled
          ></StyledInput>
          <StyledButtonPlus
            onClick={() => handleQuantityChange(item, item.quantity + 1)}
          >
            +
          </StyledButtonPlus>
        </Quantity>
        <QuantityText>Količina</QuantityText>
      </Middle>
      <Right>
        <Price>{(item.price * item.quantity).toFixed(2)} €</Price>
        <Delete onClick={() => onDeleteItem(item.id1)}>Izbriši</Delete>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 2px solid ${colors.backgroundLight};
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  width: 184px;
  padding-right: 5px;

  @media ${device.mobile} {
    width: 50%;
  }
`;

const Category = styled.div`
  margin-bottom: 8px;
  color: ${colors.textDark};
`;

const Name = styled.span`
  font-weight: 700;
`;

const Middle = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Quantity = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  font-size: 15px;
  padding: 5px 0;
  height: 35px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;

const StyledButtonPlus = styled(StyledButton)`
  border-radius: 0 8px 8px 0;
`;

const StyledButtonMinus = styled(StyledButton)`
  border-radius: 8px 0 0 8px;
`;

const StyledInput = styled(Input)`
  padding: 4px 8px;
  width: 40px;
  height: 35px;
  border-radius: 0;
  background-color: ${colors.backgroundExtraLight};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  appearance: textfield;
`;

const QuantityText = styled.div`
  color: ${colors.textDark};
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
`;

const Delete = styled(Button)`
  margin-top: 16px;
  padding: 5px 7px;
  font-size: 12px;
  border: 1px solid ${colors.primaryDark};
  background-color: transparent;
  color: ${colors.primaryDark};

  &:hover {
    background-color: ${colors.backgroundLight};
  }
`;

const Right = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Price = styled.div`
  font-weight: 700;
  text-align: right;
`;

export default CartItem;
