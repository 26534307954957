import React from "react";
import styled from "styled-components";

import { locations } from "constants/locations";
import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  paddingMedium,
  paddingExtraSmall,
  colors
} from "constants/variables";

import Box from "shared/Box";
import Button from "shared/Button";
import Title from "shared/Title";

const OrderLocation = ({ setStep, order, setOrder }) => {
  const handleLocationSelection = (location) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      location
    }));
  };

  return (
    <Wrapper>
      <Container>
        <StyledBox>
          <StyledTitle>Lokacija prevzema</StyledTitle>
          <Options>
            {locations.map((location) => (
              <OptionItem
                key={location.key}
                selected={order.location === location.key}
                onClick={() => handleLocationSelection(location.key)}
              >
                <LargeText>{location.name}</LargeText>
                <SmallText>{location.address}</SmallText>
                <SmallText>
                  Prevzem:{" "}
                  {`${location.pickupTimeStart} - ${location.pickupTimeEnd}`}
                </SmallText>
              </OptionItem>
            ))}
          </Options>
        </StyledBox>
        <ButtonWrapper>
          <Button onClick={() => setStep("items")}>Izberi jedi</Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingMedium} 0;
  background-color: ${colors.background};
  color: ${colors.text};
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    max-width: ${contentWidthNarrow};
  }
`;

const StyledBox = styled(Box)`
  background-color: transparent;
  border: 2px solid ${colors.backgroundLight};

  @media ${device.mobile} {
    padding: ${paddingExtraSmall};
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${paddingMedium};
`;

const Options = styled.div``;

const OptionItem = styled.div`
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  padding: 16px 24px;
  border: 2px solid ${colors.primaryDark};
  border-radius: 8px;
  font-weight: 500;
  color: ${({ selected }) => (selected ? colors.black : colors.primaryDark)};
  background-color: ${({ selected }) =>
    selected ? colors.primaryDark : "transparent"};

  &:not(:first-child) {
    margin-top: 16px;
  }

  @media ${device.mobile} {
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
  }
`;

const LargeText = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const SmallText = styled.div`
  font-size: 14px;
`;

export default OrderLocation;
