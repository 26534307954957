import styled, { css } from "styled-components";

import { colors } from "constants/variables";

const ButtonAdmin = styled.button`
  padding: 16px 24px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  transition: 0.2s ease-out;

  &:hover {
    background-color: ${colors.primaryDark};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #dddddd;
    `};
`;

export default ButtonAdmin;
