import React from "react";
import styled from "styled-components";

import { locations } from "constants/locations";
import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  colors,
  paddingSmall,
  paddingMedium
} from "constants/variables";

import BoxBorder from "shared/BoxBorder";
import Button from "shared/Button";
import Title from "shared/Title";

const map = require("images/map-pin.svg");
const clock = require("images/clock.svg");

const OrderIntroduction = ({ setStep }) => {
  return (
    <Wrapper>
      <Container>
        <ButtonWrapper>
          <Button onClick={() => setStep("location")}>Naročilo</Button>
        </ButtonWrapper>
        <Paragraph>Pozdravljeni,</Paragraph>
        <Paragraph>
          <ParagraphItem>
            Tukaj lahko izberete svoj topel take-away obrok, ki ga boste lahko
            prevzeli v eni od naših poslovalnic.
          </ParagraphItem>
          <ParagraphItem>
            Vaš željeni obrok lahko izberete iz naše pestre ponudbe jedi.
            Naročilo lahko izdelate za cel teden vnaprej ali pa samo za poljuben
            dan.
          </ParagraphItem>
        </Paragraph>
        <StyledTitle>Čas naročila</StyledTitle>
        <Paragraph>
          Ker stremimo k temu, da so naše jedi iz svežih in domačih sestavin,
          vas prosimo, da naročilo oddate{" "}
          <Time>najkasneje do 9:30 ure za tekoči dan</Time>. Kasnejša naročila
          niso mogoča.
        </Paragraph>
        <StyledTitle>Prevzem</StyledTitle>
        <Paragraph>Prevzem jedi je možen na naslednjih lokacijah:</Paragraph>
        <div>
          {locations.map((location) => (
            <GoogleMapsLink
              key={location.key}
              href={location.googleMapsLink}
              target="_blank"
            >
              <LocationItem>
                <LocationIcon src={map} />
                <LocationItemText bold>{location.description}</LocationItemText>
                <LocationItemText light>{location.address}</LocationItemText>
                <LocationIconClock src={clock} />
                <LocationItemText
                  bold
                >{`${location.pickupTimeStart} - ${location.pickupTimeEnd}`}</LocationItemText>
              </LocationItem>
            </GoogleMapsLink>
          ))}
        </div>
        {/* <StyledTitle>Dostava na naslov naročnika</StyledTitle>
        <Paragraph>
          Za dostavo večjega števila obrokov (nad 10) nas prosimo kontaktirajte
          na naš e-mail naslov &nbsp;
          <Mail href="mailto:info@femec.si">info@femec.si</Mail> ali
          041-635-101.
          <br />
        </Paragraph> */}
        <ButtonWrapper>
          <Button onClick={() => setStep("location")}>Naročilo</Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingSmall} 0;
  background-color: ${colors.background};
  color: ${colors.text};
  font-size: 24px;
  font-weight: 600;
`;

const Container = styled.div`
  max-width: ${contentWidthNarrow};
  margin: auto;
  @media ${device.mobile} {
    max-width: 100%;
    margin: 0 ${xMarginMobile};
  }
  text-align: center;
`;

const Paragraph = styled.div`
  font-weight: 500;
  text-align: justify;
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const ParagraphItem = styled.div`
  margin-bottom: 16px;
`;

const Time = styled.span`
  color: ${colors.primary};
`;

const StyledTitle = styled(Title)`
  margin: 32px 0 16px;
  text-align: left;
`;

const GoogleMapsLink = styled.a`
  margin-top: 16px;
  display: block;
  color: ${colors.white};
  &:hover {
    color: ${colors.white};
  }
`;

const LocationItem = styled(BoxBorder)`
  padding: 16px;
  flex: 1;
  text-align: center;
`;

const LocationIcon = styled.img`
  height: 25px;
  margin-bottom: 6px;
`;

const LocationIconClock = styled(LocationIcon)`
  margin-top: 10px;
`;

const LocationItemText = styled.div`
  font-weight: ${({ bold }) => bold && "700"};
  color: ${({ light }) => light && colors.textDark};
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

// const Mail = styled.a`
//   color: ${colors.primary};

//   &:hover {
//     color: ${colors.primaryDark};
//   }
// `;

const ButtonWrapper = styled.div`
  margin: ${paddingMedium} 0;
`;

export default OrderIntroduction;
