import styled from "styled-components";

import { colors } from "constants/variables";

const InputError = styled.div`
  margin-top: 8px;
  color: ${colors.red};
  font-size: 14px;
`;

export default InputError;
