import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { API } from "aws-amplify";
import { chain } from "lodash";
import moment from "moment";
import "moment/min/locales";

import { getLocationDiscount } from "constants/locations";
import {
  size,
  device,
  xMarginMobile,
  paddingMedium,
  colors
} from "constants/variables";
import {
  formatDate,
  createListOfWorkDays,
  getCurrentWeekday,
  isPastTodaysHourLimit
} from "utils";

import Button from "shared/Button";
import Loader from "shared/Loader";
import CartItem from "./CartItem";
import OrderItem from "./OrderItem";
import Title from "shared/Title";

moment.locale("sl");

let currentWeekday = getCurrentWeekday();
let workDays = createListOfWorkDays();

if (currentWeekday > 4) {
  // Set current weekday to Monday if it's weekend
  currentWeekday = 0;
  // Get next weeks' work days if it's weekend
  workDays = createListOfWorkDays(moment().add(1, "week"));
}

const filteredWorkDays = workDays.filter(
  ({ date }) => date.weekday() >= currentWeekday
);
const HOUR_LIMIT = "9:30";

const OrderItems = ({
  setStep,
  order,
  onAddItem,
  onDeleteItem,
  onItemQuantityChange,
  lowStockError
}) => {
  const [items, setItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [selectedDay, setSelectedDay] = useState(currentWeekday);

  useEffect(() => {
    const listItems = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await API.get("api", "/items", {
          queryStringParameters: {
            startDate: formatDate(filteredWorkDays[0].date),
            endDate: formatDate(
              filteredWorkDays[filteredWorkDays.length - 1].date
            )
          }
        });
        setItems(response);
      } catch (error) {
        switch (error.response.data.status) {
          case "dynamodb-error":
            setError(
              "Prišlo je do napake na strežniku. Poskusite ponovno kasneje."
            );
            break;
          default:
            setError("Prišlo je do napake. Poskusite ponovno kasneje.");
        }
      }

      setLoading(false);
    };

    listItems();
  }, []);

  const locationDiscount = getLocationDiscount(order.location);
  const isItemListEmpty = Object.keys(items).length === 0;
  const isCartEmpty = Object.keys(order.items).length === 0;

  return (
    <Wrapper>
      <Container>
        {(error || isItemListEmpty) && !loading ? (
          <Center>
            {error && <Message>{error}</Message>}
            {isItemListEmpty && (
              <Message>
                Trenutno ni možno naročiti jedi. Jedilnik se osveži pred vsakim
                začetkom tedna.
              </Message>
            )}
            <Button onClick={() => setStep("introduction")}>Nazaj</Button>
          </Center>
        ) : (
          <>
            {lowStockError && (
              <Center>
                <Message>{lowStockError}</Message>
              </Center>
            )}
            <Selection>
              <Days>
                {filteredWorkDays.map(({ date, label }) => (
                  <DayItem
                    key={label}
                    selected={selectedDay === date.weekday()}
                    onClick={() => setSelectedDay(date.weekday())}
                  >
                    {label.split(",")[0]}
                    <br />
                    {label.split(",")[1]}
                  </DayItem>
                ))}
              </Days>
              <ItemList>
                <StyledTitle>{workDays[selectedDay].label}</StyledTitle>
                <Loader isLoading={loading}>
                  {() => {
                    const date = workDays[selectedDay].date;
                    const formattedDate = date.format("YYYY-MM-DD");
                    return isPastTodaysHourLimit(date, HOUR_LIMIT) ? (
                      <div>
                        Naročila za tekoči dan so možna do {HOUR_LIMIT} ure.
                        <br />
                        <br />
                        Če vseeno želite naročiti, lahko pokličete na
                        <div>031-664-696.</div>
                      </div>
                    ) : !(formattedDate in items) ? (
                      <div>Za izbrani dan ni na voljo nobenih jedi.</div>
                    ) : (
                      <ItemsGrid>
                        {items[formattedDate].map((item) => (
                          <OrderItem
                            key={item.id1}
                            item={item}
                            discount={locationDiscount}
                            onAddItem={onAddItem}
                            date={formattedDate}
                            disabled={item.id1 in order.items}
                          />
                        ))}
                      </ItemsGrid>
                    );
                  }}
                </Loader>
              </ItemList>
              <Cart>
                <Title>Naročilo</Title>
                <CartContent>
                  {chain(Object.values(order.items))
                    .orderBy(["date", "meal"])
                    .groupBy("date")
                    .toPairs()
                    .value()
                    .map(([date, items]) => (
                      <CartGroup key={date}>
                        <CartDay>{moment(date).format("dddd")}</CartDay>
                        {items.map((item) => (
                          <CartItem
                            key={item.id1}
                            item={item}
                            onDeleteItem={onDeleteItem}
                            onItemQuantityChange={onItemQuantityChange}
                          />
                        ))}
                      </CartGroup>
                    ))}
                </CartContent>
                {!isCartEmpty ? (
                  <>
                    <CartPrice>
                      <CartPriceText>Skupaj</CartPriceText>
                      <CartPriceText>{order.total.toFixed(2)} €</CartPriceText>
                    </CartPrice>
                  </>
                ) : (
                  <EmptyCartText>Vaše naročilo je prazno.</EmptyCartText>
                )}
              </Cart>
            </Selection>
            <ButtonWrapper>
              <Button
                disabled={isCartEmpty}
                onClick={() => setStep("information")}
              >
                Naprej
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingMedium} 0;
  background-color: ${colors.background};
  color: ${colors.text};
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};

  @media ${device.laptop} {
    margin: auto;
    max-width: calc(${size.laptop} - 3 * ${xMarginMobile});
  }

  @media ${device.laptopM} {
    margin: auto;
    max-width: calc(${size.laptopM} - 3 * ${xMarginMobile});
  }

  @media ${device.laptopL} {
    margin: auto;
    max-width: calc(${size.laptopL} - 3 * ${xMarginMobile});
  }

  @media ${device.desktopS} {
    margin: auto;
    max-width: calc(${size.desktopS} - 3 * ${xMarginMobile});
  }
`;

const Center = styled.div`
  text-align: center;
`;

const Message = styled.div`
  margin-bottom: 20px;
`;

const Selection = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Days = styled.div`
  min-width: 140px;
  padding-right: 16px;

  @media ${device.mobile} {
    width: 100%;
    padding-right: 0;
  }
`;

const DayItem = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${colors.backgroundLight};
  border: 2px solid ${colors.backgroundLight};
  color: ${colors.textDark};
  text-transform: capitalize;

  &:not(:first-child) {
    margin-top: 16px;
  }

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${colors.backgroundExtraLight};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.backgroundLight};
      border: 2px solid ${colors.primaryDark};
      color: ${colors.primaryDark};
    `};
`;

const ItemList = styled.div`
  width: 100%;
  padding: 0 16px;

  @media ${device.mobile} {
    width: 100%;
    margin-top: ${paddingMedium};
    padding: 0;
  }
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
`;

const StyledTitle = styled(Title)`
  text-transform: capitalize;
`;

const Cart = styled.div`
  min-width: 412px;
  padding-left: 16px;

  @media ${device.mobile} {
    min-width: 100%;
    margin-top: ${paddingMedium};
    padding-left: 0;
  }
`;

const CartContent = styled.div``;

const CartGroup = styled.div`
  padding: 12px;
  border: 2px solid ${colors.backgroundLight};
  border-radius: 8px;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const CartDay = styled.div`
  color: ${colors.primary};
  text-transform: capitalize;
`;

const CartPrice = styled.div`
  margin-top: 12px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
`;

const CartPriceText = styled.div`
  font-weight: 700;
`;

const EmptyCartText = styled.div`
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${paddingMedium};
`;

export default OrderItems;
