import React from "react";
import styled from "styled-components";

import { colors, paddingSmall } from "constants/variablesAdmin";
import { formatDate, parseDate } from "utils";

import Actions from "./Actions";
import Box from "shared/Box";

const Order = ({ order, onDeleteOrder }) => {
  return (
    <StyledBox>
      <Information>
        <div>
          <NumberText>{order.number}</NumberText>
          <SmallTitle>Št. naročila</SmallTitle>
        </div>
        <div>
          <NumberText>{order.name}</NumberText>
          <SmallTitle>Ime</SmallTitle>
        </div>
        <div>
          <NumberText>{order.email}</NumberText>
          <SmallTitle>E-mail</SmallTitle>
        </div>
        <div>
          <NumberText>{order.phone}</NumberText>
          <SmallTitle>Telefon</SmallTitle>
        </div>
        <div>
          <NumberText>{order.location}</NumberText>
          <SmallTitle>Prevzem</SmallTitle>
        </div>
        <div>
          <NumberText>{formatDate(parseDate(order.date), "sl")}</NumberText>
          <SmallTitle>Datum naročila</SmallTitle>
        </div>
        <Actions onDelete={() => onDeleteOrder(order.id1)} />
      </Information>
      <OrderData>
        <thead>
          <OrderHeader>
            <th>
              <SmallTitle>Dan</SmallTitle>
            </th>
            <th>
              <SmallTitle>Obrok</SmallTitle>
            </th>
            <th>
              <SmallTitle>Ime</SmallTitle>
            </th>
            <th>
              <SmallTitle>Količina</SmallTitle>
            </th>
            <th>
              <SmallTitle>Cena</SmallTitle>
            </th>
          </OrderHeader>
        </thead>
        <tbody>
          {order.items.map((item) => (
            <tr key={item.id2}>
              <td>
                <ItemElement>
                  {formatDate(parseDate(item.date), "sl")}
                </ItemElement>
              </td>
              <td>
                <ItemElement>{item.meal}</ItemElement>
              </td>
              <td>
                <ItemElement>{item.name}</ItemElement>
              </td>
              <td>
                <ItemElement>{item.quantity}</ItemElement>
              </td>
              <td>
                <ItemElement>
                  {(item.quantity * item.price).toFixed(2)} €
                </ItemElement>
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <SmallTitle>Skupaj</SmallTitle>
            </td>
            <td>
              <ItemElement>{order.total.toFixed(2)} €</ItemElement>
            </td>
          </tr>
        </tbody>
      </OrderData>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  margin-top: ${paddingSmall};
`;

const Information = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NumberText = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const SmallTitle = styled.div`
  color: ${colors.textDark};
  font-size: 14px;
`;

const OrderData = styled.table`
  margin-top: 40px;
  width: 100%;
  border-collapse: collapse;

  tr {
    &:not(:last-child) {
      border-bottom: 1px solid #cccccc;
    }
  }

  td,
  th {
    padding: 8px 8px;
    text-align: left;

    &:nth-of-type(1) {
      width: 12%;
    }
    &:nth-of-type(2) {
      width: 18%;
    }
    &:nth-of-type(3) {
      width: 55%;
    }
    &:nth-of-type(4) {
      width: 5%;
      text-align: center;
    }
    &:nth-of-type(5) {
      width: 10%;
      text-align: right;
    }
  }
`;

const OrderHeader = styled.tr`
  border-bottom: 1px solid #cccccc;
`;

const ItemElement = styled.div``;

export default Order;
