import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  colors
} from "constants/variables";

import Cover from "components/Orders/Cover";
import Button from "shared/Button";

const PageNotFound = () => {
  return (
    <Wrapper>
      <Cover />
      <Container>
        <Message>Ta stran ne obstaja.</Message>
        <Link to="/">
          <Button>Naročila</Button>
        </Link>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 18px 0;
  background-color: ${colors.black};
  color: ${colors.text};
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    max-width: ${contentWidthNarrow};
  }
  text-align: center;
`;

const Message = styled.div`
  margin: 25px 0;
`;

export default PageNotFound;
