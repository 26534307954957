import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";
import { orderBy } from "lodash";
import moment from "moment";
import "moment/min/locales";

import {
  device,
  contentWidthAdmin,
  xMarginMobile,
  colors,
  paddingMedium
} from "constants/variablesAdmin";
import { formatDate, getCurrentWeekday, createListOfWorkDays } from "utils";

import AdminOrdersHeader from "./AdminOrdersHeader";
import Order from "./Order";
import Loader from "shared/Loader";

moment.locale("sl");

// Get next weeks' work days if it's weekend, otherwise get this weeks'
let workDays =
  getCurrentWeekday() > 4
    ? createListOfWorkDays(moment().add(1, "week"))
    : createListOfWorkDays();

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [startDay, setStartDay] = useState(workDays[0].date);
  const [endDay, setEndDay] = useState(workDays[workDays.length - 1].date);

  const handleWeekChange = (direction) => {
    setStartDay((prevStartDay) => {
      const newStartDay = prevStartDay.clone();
      return direction === "previous"
        ? newStartDay.subtract(7, "days")
        : newStartDay.add(7, "days");
    });
    setEndDay((prevEndDay) => {
      const newEndDay = prevEndDay.clone();
      return direction === "previous"
        ? newEndDay.subtract(7, "days")
        : newEndDay.add(7, "days");
    });
  };

  const listOrders = useCallback(async () => {
    setLoading(true);
    setError("");

    try {
      const response = await API.get("api", "/orders", {
        queryStringParameters: {
          startDate: formatDate(startDay),
          endDate: formatDate(endDay)
        }
      });
      setOrders(orderBy(response, "createdAt", "desc"));
    } catch (error) {
      switch (error.response.data.status) {
        case "authorization-error":
        case "cognito-error":
          setError(
            "Prišlo je do napake pri avtorizaciji. Ponovno se prijavite."
          );
          break;
        case "dynamodb-error":
          setError("Prišlo je do napake na strežniku.");
          break;
        default:
          setError("Prišlo je do napake.");
      }
    }

    setLoading(false);
  }, [startDay, endDay]);

  useEffect(() => {
    listOrders();
  }, [listOrders]);

  const handleDeleteOrder = (id) => {
    const deleteOrder = async () => {
      setLoading(true);
      setError("");

      try {
        await API.del("api", `/orders/${id}`);
        listOrders();
      } catch (error) {
        switch (error.response.data.status) {
          case "authorization-error":
          case "cognito-error":
            setError(
              "Prišlo je do napake pri avtorizaciji. Ponovno se prijavite."
            );
            break;
          case "dynamodb-error":
            setError("Prišlo je do napake na strežniku.");
            break;
          default:
            setError("Prišlo je do napake.");
        }
      }
    };

    deleteOrder();
  };

  return (
    <Wrapper>
      <Container>
        <AdminOrdersHeader
          startDay={startDay}
          endDay={endDay}
          onWeekChange={handleWeekChange}
        />
        <Loader isLoading={loading}>
          {() =>
            error ? (
              <div>{error}</div>
            ) : orders && orders.length > 0 ? (
              <OrdersList>
                {orders.map((order) => (
                  <Order
                    key={order.id1}
                    order={order}
                    onDeleteOrder={handleDeleteOrder}
                  />
                ))}
              </OrdersList>
            ) : (
              <div>Za izbrani teden ni naročil</div>
            )
          }
        </Loader>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingMedium} 0;
  background-color: ${colors.backgroundDark};
  color: ${colors.text};
  font-size: 20px;
`;

const Container = styled.div`
  max-width: ${contentWidthAdmin};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
`;

const OrdersList = styled.div`
  margin-bottom: 40px;
`;

export default AdminOrders;
