export const colors = {
  primary: "#a28f65",
  secondary: "#F49EBC",
  tertiary: "#D9D9FF",
  primaryDark: "#877854",
  red: "#a33349",
  redDark: "#872a3d",
  green: "#4ea571",
  greenDark: "#418a5e",
  white: "#FFFFFF",
  black: "#000000",
  text: "#000000",
  textLight: "#666666",
  textExtraLight: "#aaaaaa",
  textDark: "#666666",
  background: "#FFFFFF",
  backgroundLight: "#FFFFFF",
  backgroundDark: "#efefef",
  backgroundExtraLight: "#f7f7f7"
};

export const paddingSmall = "24px";
export const paddingMedium = "72px";
export const paddingLarge = "100px";
export const paddingExtraSmall = "16px";

export const xMarginMobile = "16px";

export const contentWidth = "950px";
export const contentWidthNarrow = "600px";
export const contentWidthWide = "1800px";
export const contentWidthAdmin = "1050px";

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px"
};

export const device = {
  mobile: `(max-width: ${size.laptop})`,
  laptop: `(min-width: ${size.laptop})`
};
