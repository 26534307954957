import styled from "styled-components";

import { colors } from "constants/variables";

const Title = styled.div`
  color: ${colors.primary};
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
`;

export default Title;
