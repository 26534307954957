import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  device,
  contentWidthAdmin,
  xMarginMobile,
  colors,
  paddingMedium
} from "constants/variablesAdmin";
import { formatDate } from "utils";

import Button from "shared/ButtonAdmin";
import Title from "shared/Title";

const plus = require("images/plus.svg");
const minus = require("images/minus.svg");

const AdminItemsHeader = ({ startDay, endDay, onWeekChange }) => {
  return (
    <Wrapper>
      <Container>
        <WeekPicker>
          <MinusButton onClick={() => onWeekChange("previous")}>
            <img src={minus} alt="-" />
          </MinusButton>
          <PlusButton onClick={() => onWeekChange("next")}>
            <img src={plus} alt="+" />
          </PlusButton>
          <StyledTitle>
            {formatDate(startDay, "sl")} - {formatDate(endDay, "sl")}
          </StyledTitle>
        </WeekPicker>
        <Link to="/admin/items/add">
          <Button>Nova jed</Button>
        </Link>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: ${paddingMedium};
  color: ${colors.text};
  font-size: 20px;
`;

const Container = styled.div`
  max-width: ${contentWidthAdmin};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }

  display: flex;
  justify-content: space-between;
`;

const WeekPicker = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 72px;
`;

const MinusButton = styled(StyledButton)``;

const PlusButton = styled(StyledButton)`
  margin: 0 16px 0 12px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

export default AdminItemsHeader;
