import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: Component, props: childProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        childProps.isAuthenticated ? (
          <Redirect
            to={
              getQueryParam(props.location.search, "redirect") ||
              "/admin/orders"
            }
          />
        ) : (
          <Component {...props} {...childProps} />
        )
      }
    />
  );
};

function getQueryParam(queryString, param) {
  return new URLSearchParams(queryString).get(param);
}
