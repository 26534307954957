import React from "react";
import styled from "styled-components";

import { colors } from "constants/variables";

import Button from "shared/Button";

const Item = ({ item, discount, onAddItem, date, disabled }) => {
  return (
    <Wrapper>
      <Left>
        <Category>{item.meal}</Category>
        <Name>{item.name}</Name>
        {item.properties.length > 0 && (
          <Description>{item.properties.join(", ")}</Description>
        )}
        {item.allergens.length > 0 && (
          <Description>Alergeni: {item.allergens.join(", ")}</Description>
        )}
      </Left>
      <Right>
        <StyledButton
          onClick={() => onAddItem({ ...item, date })}
          disabled={disabled}
        >
          Dodaj
        </StyledButton>
        <Price>{(item.price * (1 - discount)).toFixed(2)} €</Price>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 12px;
  border: 2px solid ${colors.backgroundLight};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  padding-right: 16px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Category = styled.div`
  margin-bottom: 4px;
  color: ${colors.textDark};
`;

const Name = styled.div`
  margin-bottom: 4px;
  font-weight: 700;
`;

const Description = styled.div`
  color: ${colors.textDark};
  margin-bottom: 4px;
  font-size: 12px;
`;

const Price = styled.div`
  margin-top: 16px;
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  padding: 8px;
  font-size: 14px;
`;

export default Item;
