import styled from "styled-components";

import { colors, paddingSmall } from "constants/variables";

const BoxBorder = styled.div`
  border: 2px solid ${colors.backgroundLight};
  padding: ${paddingSmall};
  border-radius: 8px;
`;

export default BoxBorder;
