import styled from "styled-components";

import { colors, paddingSmall } from "constants/variablesAdmin";

const Box = styled.div`
  background-color: ${colors.backgroundLight};
  padding: ${paddingSmall};
  border-radius: 8px;
`;

export default Box;
