import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { API } from "aws-amplify";
import moment from "moment";
import "moment/min/locales";

import {
  arrayToObject,
  getCurrentWeekday,
  getTodaysDate,
  createListOfWorkDays,
  formatDate
} from "utils";

import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  colors,
  paddingMedium
} from "constants/variablesAdmin";

import ActionsEditAdd from "./ActionsEditAdd";
import Box from "shared/Box";
import InputAdmin from "shared/InputAdmin";
import InputLabel from "shared/InputLabel";
import Title from "shared/Title";
import Loader from "shared/Loader";

moment.locale("sl");

const meals = [
  "Malica 1",
  "Malica 1 - A",
  "Malica 1 - B",
  "Malica 2",
  "Malica 2 - A",
  "Malica 2 - B",
  "Malica 3",
  "Malica 3 - A",
  "Malica 3 - B",
  "Malica 4",
  "Malica 4 - A",
  "Malica 4 - B",
  "Malica 5",
  "Malica 5 - A",
  "Malica 5 - B",
  "Tedenska ponudba",
  "Dnevna juha",
  "Dnevna juha - mala",
  "Dnevna juha - velika",
  "Solata 1",
  "Solata 2"
];

const allergens = [
  "gluten",
  "raki, ribe in mehkužci",
  "jajčne beljakovine",
  "arašidi",
  "mlečne beljakovine",
  "soja",
  "oreščki",
  "zelena",
  "gorčično seme",
  "sezam",
  "žveplo",
  "volčji bob"
];

const properties = [
  "mesni obrok",
  "ribji obrok",
  "vegetarijanski obrok",
  "veganski obrok",
  "brezglutenski obrok"
];

const ITEM_INITIAL_STATE = {
  meal: "",
  name: "",
  stock: 0,
  price: 0,
  allergens: arrayToObject(allergens, false),
  properties: arrayToObject(properties, false),
  date:
    getCurrentWeekday() > 4
      ? formatDate(moment().add(1, "week").startOf("week"))
      : getTodaysDate()
};

const AdminItemAdd = () => {
  const [item, setItem] = useState(ITEM_INITIAL_STATE);
  const [allWeek, setAllWeek] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = ({ name, value }) => {
    setItem((prevItem) => ({
      ...prevItem,
      [name]: value
    }));
  };

  const handleCheckboxGroupChange = (name, property) => {
    setItem((prevItem) => ({
      ...prevItem,
      [name]: {
        ...prevItem[name],
        [property]: !prevItem[name][property]
      }
    }));
  };

  useEffect(() => {
    if (item.meal === "Tedenska ponudba") {
      setAllWeek(true);
    }
  }, [item]);

  const handleSave = async () => {
    setLoading(true);
    setMessage("");
    setError("");

    const newItem = {
      ...item,
      name: item.name.trim(),
      stock: parseInt(item.stock),
      price: Math.round(item.price * 100) / 100,
      allergens: Object.entries(item.allergens)
        .filter(([_, value]) => value)
        .map(([key, _]) => key)
        .sort(),
      properties: Object.entries(item.properties)
        .filter(([_, value]) => value)
        .map(([key, _]) => key)
        .sort()
    };

    try {
      if (allWeek) {
        for (const { date } of createListOfWorkDays(moment(item.date))) {
          await API.post("api", "/items", {
            body: {
              ...newItem,
              date: formatDate(date)
            }
          });
        }
        setMessage(`Jed "${newItem.name}" uspešno dodana za vse dni v tednu.`);
      } else {
        await API.post("api", "/items", {
          body: newItem
        });
        setMessage(`Jed "${newItem.name}" uspešno dodana.`);
      }
      setItem({ ...ITEM_INITIAL_STATE, date: newItem.date });
      setAllWeek(false);
    } catch (error) {
      switch (error.response.data.status) {
        case "dynamodb-error":
          setError("Prišlo je do napake na strežniku.");
          break;
        default:
          setError("Prišlo je do napake.");
      }
    }

    setLoading(false);
  };

  const isAnyInputEmpty =
    !item.meal || !item.name || !item.stock || !item.price || !item.date;

  return (
    <Wrapper>
      <StyledLoader isLoading={loading} />
      {!loading && (
        <Container>
          <MessageError>{message || error}</MessageError>
          <Title>Nova jed</Title>
          <StyledBox>
            <InputWrapper>
              <StyledInputLabel>Obrok</StyledInputLabel>
              <StyledDropdownButton
                id="dropdown"
                title={item.meal}
                onSelect={(value) => handleChange({ name: "meal", value })}
              >
                {meals.map((meal) => (
                  <StyledDropdownItem
                    id="dropdown-item"
                    key={meal}
                    eventKey={meal}
                    active={item.meal === meal}
                  >
                    {meal}
                  </StyledDropdownItem>
                ))}
              </StyledDropdownButton>
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel>Ime</StyledInputLabel>
              <InputAdmin
                name="name"
                type="text"
                value={item.name}
                onChange={(event) => handleChange(event.target)}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel>Zaloga</StyledInputLabel>
              <InputAdmin
                name="stock"
                type="number"
                value={item.stock}
                min="1"
                onChange={(event) => handleChange(event.target)}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel>Cena</StyledInputLabel>
              <InputAdmin
                name="price"
                type="number"
                value={item.price}
                min="0"
                step="any"
                onChange={(event) => handleChange(event.target)}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel>Alergeni</StyledInputLabel>
              {allergens.map((allergen) => (
                <div key={allergen}>
                  <input
                    id={allergen}
                    type="checkbox"
                    checked={item.allergens[allergen]}
                    onChange={() =>
                      handleCheckboxGroupChange("allergens", allergen)
                    }
                  />
                  <CheckboxLabel htmlFor={allergen}>{allergen}</CheckboxLabel>
                </div>
              ))}
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel>Vrsta obroka</StyledInputLabel>
              {properties.map((property) => (
                <div key={property}>
                  <input
                    id={property}
                    type="checkbox"
                    checked={item.properties[property]}
                    onChange={() =>
                      handleCheckboxGroupChange("properties", property)
                    }
                  />
                  <CheckboxLabel htmlFor={property}>{property}</CheckboxLabel>
                </div>
              ))}
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel>Dan</StyledInputLabel>
              <InputAdmin
                id="start"
                name="date"
                type="date"
                value={item.date}
                onChange={(event) => handleChange(event.target)}
                required
              />
              <AllWeekCheckbox>
                <input
                  id="all-week"
                  type="checkbox"
                  checked={allWeek}
                  onChange={() => setAllWeek((prevAllWeek) => !prevAllWeek)}
                />
                <CheckboxLabel htmlFor="all-week">Tedenska jed</CheckboxLabel>
              </AllWeekCheckbox>
            </InputWrapper>
          </StyledBox>
          <ActionsEditAdd onSave={handleSave} disabled={isAnyInputEmpty} />
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px 0 ${paddingMedium} 0;
  background-color: ${colors.backgroundDark};
  color: ${colors.text};
  font-size: 20px;
`;

const Container = styled.div`
  max-width: ${contentWidthNarrow};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  color: ${colors.primary};
  font-weight: 700;
`;

const StyledDropdownButton = styled(DropdownButton)`
  #dropdown {
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.backgroundExtraLight};
    border: 1px solid #dddddd;
    color: ${colors.text};
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 8px;
    padding: 16px;
  }

  .dropdown-toggle:empty::after {
    margin-left: 98%;
  }
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  #dropdown-item {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
`;

const StyledBox = styled(Box)`
  margin-top: 40px;
`;

const InputWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const AllWeekCheckbox = styled.div`
  margin-top: 16px;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  transform: translateY(-2px);
`;

const MessageError = styled.div`
  height: 25px;
  margin-bottom: 15px;
`;

const StyledLoader = styled(Loader)`
  margin-top: 72px;
`;

export default AdminItemAdd;
