import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";
import moment from "moment";
import "moment/min/locales";

import {
  device,
  contentWidthAdmin,
  xMarginMobile,
  colors,
  paddingMedium
} from "constants/variablesAdmin";
import { formatDate, getCurrentWeekday, createListOfWorkDays } from "utils";

import AdminItemsHeader from "./AdminItemsHeader";
import Item from "./Item";
import Title from "shared/Title";
import Loader from "shared/Loader";

moment.locale("sl");

// Get next weeks' work days if it's weekend, otherwise get this weeks'
let workDays =
  getCurrentWeekday() > 4
    ? createListOfWorkDays(moment().add(1, "week"))
    : createListOfWorkDays();

const AdminItems = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [startDay, setStartDay] = useState(workDays[0].date);
  const [endDay, setEndDay] = useState(workDays[workDays.length - 1].date);

  const handleWeekChange = (direction) => {
    setStartDay((prevStartDay) => {
      const newStartDay = prevStartDay.clone();
      return direction === "previous"
        ? newStartDay.subtract(7, "days")
        : newStartDay.add(7, "days");
    });
    setEndDay((prevEndDay) => {
      const newEndDay = prevEndDay.clone();
      return direction === "previous"
        ? newEndDay.subtract(7, "days")
        : newEndDay.add(7, "days");
    });
  };

  const listItems = useCallback(async () => {
    setLoading(true);
    setError("");

    try {
      const response = await API.get("api", "/items", {
        queryStringParameters: {
          startDate: formatDate(startDay),
          endDate: formatDate(endDay),
          includeZeroStock: true
        }
      });
      setItems(response);
    } catch (error) {
      switch (error.response.data.status) {
        case "dynamodb-error":
          setError("Prišlo je do napake na strežniku.");
          break;
        default:
          setError("Prišlo je do napake.");
      }
    }

    setLoading(false);
  }, [startDay, endDay]);

  useEffect(() => {
    listItems();
  }, [listItems]);

  const handleDeleteItem = (id) => {
    const deleteItem = async () => {
      setLoading(true);
      setError("");

      try {
        await API.del("api", `/items/${id}`);
        listItems();
      } catch (error) {
        switch (error.response.data.status) {
          case "dynamodb-error":
            setError("Prišlo je do napake na strežniku.");
            break;
          default:
            setError("Prišlo je do napake.");
        }
      }
    };

    deleteItem();
  };

  return (
    <Wrapper>
      <Container>
        <AdminItemsHeader
          startDay={startDay}
          endDay={endDay}
          onWeekChange={handleWeekChange}
        />
        <Loader isLoading={loading}>
          {() =>
            error ? (
              <div>{error}</div>
            ) : items && Object.keys(items).length > 0 ? (
              Object.keys(items).map((date) => (
                <div key={date}>
                  <StyledTitle>
                    {moment(date).format("dddd, D. M. YYYY")}
                  </StyledTitle>
                  <ItemsList>
                    {items[date] &&
                      items[date].map((item) => (
                        <Item
                          key={item.id1}
                          item={item}
                          onDeleteItem={handleDeleteItem}
                        />
                      ))}
                  </ItemsList>
                </div>
              ))
            ) : (
              <div>Za izbrani teden ni jedi</div>
            )
          }
        </Loader>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingMedium} 0;
  background-color: ${colors.backgroundDark};
  color: ${colors.text};
  font-size: 20px;
`;

const Container = styled.div`
  max-width: ${contentWidthAdmin};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
`;

const StyledTitle = styled(Title)`
  text-transform: capitalize;
`;

const ItemsList = styled.div`
  margin-bottom: 40px;
`;

export default AdminItems;
