import React from "react";
import styled from "styled-components";

import {
  device,
  contentWidth,
  xMarginMobile,
  colors,
} from "constants/variables";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Text>
          Poleg raznovrstnih malic, vam nudimo tudi vse ostale oblike catering
          storitev po predhodnem dogovoru.{" "}
        </Text>
        <Company>
          Femec d.o.o. | Dunajska cesta 160, 1000 Ljubljana | info@femec.si
        </Company>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 12px 0;
  background-color: ${colors.black};
  color: ${colors.white};
`;

const Container = styled.div`
  max-width: ${contentWidth};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
  text-align: center;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${colors.textDark};
  margin-bottom: 8px;
`;

const Company = styled.div`
  font-size: 14px;
  color: ${colors.textDark};
`;

export default Footer;
