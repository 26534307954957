import React from "react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

import { colors } from "constants/variables";

const Loader = ({ className, isLoading = true, children }) =>
  isLoading ? (
    <Container className={className}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Container>
  ) : (
    (children && children()) || null
  );

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary};
`;

export default Loader;
