import React, { useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  colors,
  paddingMedium
} from "constants/variablesAdmin";

import InputAdmin from "shared/InputAdmin";
import InputLabel from "shared/InputLabel";
import Title from "shared/Title";
import Button from "shared/ButtonAdmin";
import Loader from "shared/Loader";

const AdminLogin = ({
  setUser,
  isAuthenticating,
  setAuthenticating,
  setAuthenticated
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setAuthenticating(true);
    setError("");

    try {
      const { attributes } = await Auth.signIn(email, password);
      setUser(attributes);
      setAuthenticated(true);
      setAuthenticating(false);
    } catch (error) {
      setError("Prijava ni uspela.");
      setAuthenticating(false);
    }
  };

  const isAnyInputEmpty = !email || !password;

  return (
    <Wrapper>
      <Container>
        {isAuthenticating ? (
          <StyledLoader />
        ) : (
          <>
            <Title>Prijava v nadzorno ploščo</Title>
            <Form onSubmit={handleSubmit}>
              <InputWrapper>
                <StyledInputLabel>Email</StyledInputLabel>
                <InputAdmin
                  name="email"
                  type="text"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <StyledInputLabel>Geslo</StyledInputLabel>
                <InputAdmin
                  name="password"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </InputWrapper>
              {error && <Error>{error}</Error>}
              <StyledButton type="submit" disabled={isAnyInputEmpty}>
                Prijava
              </StyledButton>
            </Form>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px 0 ${paddingMedium} 0;
  background-color: ${colors.backgroundDark};
  color: ${colors.text};
  font-size: 20px;
`;

const Container = styled.div`
  max-width: ${contentWidthNarrow};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
`;

const Form = styled.form`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  color: ${colors.primary};
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  margin-top: 25px;
`;

const Error = styled.div`
  height: 25px;
  margin-top: 25px;
`;

const StyledLoader = styled(Loader)`
  margin-top: 72px;
`;

export default withRouter(AdminLogin);
