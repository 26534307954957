import styled from "styled-components";

import { colors } from "constants/variables";

const Input = styled.input`
  outline: none;
  border: none;
  background-color: ${colors.backgroundLight};
  color: ${colors.text};
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  width: 100%;
`;

export default Input;
