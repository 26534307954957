import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { colors } from "./constants/variables";

import Order from "components/Orders/Order";
import Admin from "components/Admin/Admin";
import PageNotFound from "components/Orders/PageNotFound";

function App() {
  return (
    <Wrapper>
      <Router>
        <Switch>
          <Route path="/" exact component={Order} />
          <Route path="/admin" component={Admin} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${colors.background};
  min-height: 100vh;
`;

export default App;
