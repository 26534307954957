import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors, paddingSmall } from "constants/variablesAdmin";

import Button from "shared/ButtonAdmin";

const ActionsEditAdd = ({ onSave, disabled }) => {
  return (
    <Buttons>
      <Link to="/admin/items">
        <ButtonCancel>Prekliči</ButtonCancel>
      </Link>
      <ButtonSave onClick={onSave} disabled={disabled}>
        Shrani
      </ButtonSave>
    </Buttons>
  );
};

const Buttons = styled.div`
  margin-top: ${paddingSmall};
  display: flex;
  justify-content: center;
`;

const ButtonCancel = styled(Button)`
  margin-right: 16px;
  width: 120px;
  background-color: ${colors.red};

  &:hover {
    background-color: ${colors.redDark};
  }
`;

const ButtonSave = styled(Button)`
  width: 120px;
  margin-right: 16px;
  ${({ disabled }) => !disabled && `background-color: ${colors.green}`};

  &:hover {
    background-color: ${colors.greenDark};
  }
`;

export default ActionsEditAdd;
