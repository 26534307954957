import React, { useState } from "react";
import styled from "styled-components";

import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  paddingMedium,
  paddingExtraSmall,
  colors
} from "constants/variables";
import { isEmailValid, isPhoneNumberValid } from "utils";

import Box from "shared/Box";
import Button from "shared/Button";
import Input from "shared/Input";
import InputLabel from "shared/InputLabel";
import InputWrapper from "shared/InputWrapper";
import InputError from "shared/InputError";
import Title from "shared/Title";

const OrderInformation = ({
  setStep,
  order,
  setOrder,
  onSubmitOrder,
  submitOrderError
}) => {
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOrder((prevOrder) => ({
      ...prevOrder,
      [name]: value
    }));
  };

  const handleSubmitOrder = () => {
    const err = {};

    if (!isEmailValid(order.email)) {
      err["email"] = "E-mail naslov ni veljaven";
    } else if (order.email.endsWith("@gov.si")) {
      err["email"] =
        "Opažamo težavo pri pošiljanju potrditvenih sporočil na e-mail naslove z domeno gov.si. Prosimo vas, da za naročilo uporabite drug e-mail naslov. Težavo odpravljamo in se vam opravičujemo za nevšečnost.";
    }

    if (!isPhoneNumberValid(order.phone)) {
      err["phone"] = "Telefonska številka ni veljavna";
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    onSubmitOrder();
  };

  const isAnyInputEmpty = !order.name || !order.email || !order.phone;

  return (
    <Wrapper>
      <Container>
        {submitOrderError ? (
          <NoItems>
            <Message>{submitOrderError}</Message>
            <Button onClick={() => setStep("items")}>Nazaj</Button>
          </NoItems>
        ) : (
          <>
            <StyledBox>
              <StyledTitle>Vaši podatki</StyledTitle>
              <InputWrapper>
                <InputLabel>Ime in priimek</InputLabel>
                <Input
                  type="text"
                  name="name"
                  value={order.name}
                  onChange={handleInputChange}
                />
                {errors.name && <InputError>{errors.name}</InputError>}
              </InputWrapper>
              <InputWrapper>
                <InputLabel>E-mail</InputLabel>
                <Input
                  type="email"
                  name="email"
                  value={order.email}
                  onChange={handleInputChange}
                />
                {errors.email && <InputError>{errors.email}</InputError>}
              </InputWrapper>
              <InputWrapper>
                <InputLabel>Telefonska številka</InputLabel>
                <Input
                  type="tel"
                  name="phone"
                  value={order.phone}
                  onChange={handleInputChange}
                />
                {errors.phone && <InputError>{errors.phone}</InputError>}
              </InputWrapper>
            </StyledBox>
            <ButtonWrapper>
              <Button disabled={isAnyInputEmpty} onClick={handleSubmitOrder}>
                Oddaj naročilo
              </Button>
            </ButtonWrapper>
            <Disclaimer>
              Spoštovani, obveščamo vas, da bomo vaše identifikacijske podatke,
              ki obsegajo ime in priimek, ter kontaktne podatke, ki obsegajo
              e-mail naslov, telefonsko številko in naslov za dostavo, uporabili
              izključno za namene naročanja hrane. Obdelava in varna uporaba
              vaših osebnih podatkov se vrši v skladu z Uredbo GDPR, Zakonom o
              varstvu osebnih podatkov ZVOP-1 in Uredbo (EU) 2016/679. Podjetje
              Femec d.o.o., ki je upravljalec vaših osebnih podatkov izjavlja,
              da nosi polno odgovornost za zakonito obdelavo vaših podatkov ter
              da le-teh ne bo prenašalo izven zbirke osebnih podatkov. V kolikor
              menite, da so kršene vaše pravice se lahko obrnete na
              Informacijskega pooblaščenca RS.
            </Disclaimer>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingMedium} 0;
  background-color: ${colors.background};
  color: ${colors.text};
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    max-width: ${contentWidthNarrow};
  }
`;

const NoItems = styled.div`
  text-align: center;
`;

const Message = styled.div`
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 40px;
`;

const StyledBox = styled(Box)`
  background-color: transparent;
  border: 2px solid ${colors.backgroundLight};

  @media ${device.mobile} {
    padding: ${paddingExtraSmall};
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${paddingMedium};
`;

const Disclaimer = styled.div`
  margin-top: ${paddingMedium};
  color: ${colors.textDark};
  font-size: 12px;
  text-align: justify;
`;

export default OrderInformation;
