import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

import { colors } from "constants/variablesAdmin";

// const editIcon = require("../../images/edit.svg");
const deleteIcon = require("../../images/trash.svg");

const Actions = ({ onEdit, onDelete }) => {
  return (
    <Wrapper>
      {/* <ActionsItem onClick={onEdit}>
        <StyledLink to="/admin/items/edit">
          <IconWrapper>
            <Icon src={editIcon} />
          </IconWrapper>
          <ItemActionText>Uredi</ItemActionText>
        </StyledLink>
      </ActionsItem> */}
      <ActionsItem onClick={onDelete}>
        <IconWrapperDelete>
          <Icon src={deleteIcon} />
        </IconWrapperDelete>
        <ItemActionText>Izbriši</ItemActionText>
      </ActionsItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const ActionsItem = styled.div`
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

// const StyledLink = styled(Link)`
//   text-decoration: none;
// `;

const IconWrapper = styled.div`
  background-color: ${colors.primary};
  width: 40px;
  height: 40px;
  border-radius: 100%;

  &:not(:first-child) {
    margin-left: 16px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover {
    background-color: ${colors.primaryDark};
  }
`;

const IconWrapperDelete = styled(IconWrapper)`
  background-color: ${colors.red};

  &:hover {
    background-color: ${colors.redDark};
  }
`;

const Icon = styled.img``;

const ItemActionText = styled.div`
  margin-top: 8px;
  color: ${colors.textLight};
  font-weight: 500;
  font-size: 14px;

  &:hover ${IconWrapper} {
    background-color: ${colors.primaryDark};
  }
`;

export default Actions;
