import React, { useState } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";

import {
  device,
  contentWidthAdmin,
  xMarginMobile,
  colors,
  paddingSmall
} from "constants/variablesAdmin";
import { formatDate, createListOfWorkDays } from "utils";

import Button from "shared/Button";
import Title from "shared/Title";
import Loader from "shared/Loader";

const workDays = createListOfWorkDays();

const plus = require("images/plus.svg");
const minus = require("images/minus.svg");
const download = require("images/download.svg");

const AdminOrdersHeader = ({ startDay, endDay, onWeekChange }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleReportDownload = async (date) => {
    const s2ab = (data) => {
      const buffer = new ArrayBuffer(data.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i !== data.length; i++) {
        view[i] = data.charCodeAt(i) & 0xff;
      }
      return buffer;
    };

    try {
      setLoading(true);
      setError("");

      const response = await API.get("api", "/reports", {
        responseType: "text",
        response: true,
        queryStringParameters: { date }
      });
      const data = s2ab(atob(response.data));
      const filename = decodeURIComponent(
        response.headers["content-disposition"].split("''")[1]
      );
      const blob = new Blob([data], {
        type: "application/vnd.ms-excel"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      if (typeof link.download === "undefined") {
        link.setAttribute("target", "_blank");
      }
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      switch (error.response.data.status) {
        case "authorization-error":
        case "cognito-error":
          setError(
            "Prišlo je do napake pri avtorizaciji. Ponovno se prijavite."
          );
          break;
        case "excel-error":
          setError("Prišlo je do napake pri generiranju excel datoteke.");
          break;
        case "dynamodb-error":
          setError("Prišlo je do napake na strežniku.");
          break;
        default:
          setError("Prišlo je do napake.");
      }
    }

    setLoading(false);
  };

  return (
    <Wrapper>
      <Container>
        <WeekPicker>
          <MinusButton onClick={() => onWeekChange("previous")}>
            <img src={minus} alt="-" />
          </MinusButton>
          <PlusButton onClick={() => onWeekChange("next")}>
            <img src={plus} alt="+" />
          </PlusButton>
          <StyledTitle>
            {formatDate(startDay, "sl")} - {formatDate(endDay, "sl")}
          </StyledTitle>
        </WeekPicker>
        <Title>Prenos seznamov naročil</Title>
        <DownloadWrapper>
          {workDays.map((item, key) => (
            <DownloadItem
              key={key}
              onClick={() =>
                handleReportDownload(item.date.format("YYYY-MM-DD"))
              }
            >
              <Text>
                <Day>{item.label.split(",")[0]}</Day>
                <div>{item.label.split(",")[1]}</div>
              </Text>
              <Icon src={download} />
            </DownloadItem>
          ))}
          <StyledLoader isLoading={loading} />
        </DownloadWrapper>
        <Error>{error}</Error>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 32px;
  color: ${colors.text};
`;

const Container = styled.div`
  max-width: ${contentWidthAdmin};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
`;

const WeekPicker = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 72px;
`;

const MinusButton = styled(StyledButton)``;

const PlusButton = styled(StyledButton)`
  margin: 0 16px 0 12px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const DownloadWrapper = styled.div`
  margin-top: ${paddingSmall};
  display: flex;
`;

const DownloadItem = styled.div`
  min-width: 160px;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.primary};
  cursor: pointer;

  color: ${colors.white};

  border-radius: 8px;
  padding: 8px 16px;

  &:not(:first-child) {
    margin-left: 16px;
  }

  &:hover {
    background-color: ${colors.primaryDark};
  }
`;

const Text = styled.div``;

const Icon = styled.img``;

const Day = styled.div`
  text-transform: capitalize;
`;

const StyledLoader = styled(Loader)`
  margin-left: 50px;
  transform: translateY(50%);
`;

const Error = styled.div`
  height: 20px;
  margin-top: 20px;
`;

export default AdminOrdersHeader;
