import React from "react";
import styled from "styled-components";

import { colors, paddingSmall } from "constants/variablesAdmin";

import Actions from "./Actions";
import Box from "shared/Box";

const Item = ({ item, onDeleteItem }) => {
  return (
    <StyledBox>
      <TextWrapper>
        <Meal>{item.meal}</Meal>
        <Name>{item.name}</Name>
        {item.allergens.length > 0 && (
          <StyledSmallTitle>
            Alergeni: {item.allergens.join(", ")}
          </StyledSmallTitle>
        )}
        {item.properties.length > 0 && (
          <StyledSmallTitle>{item.properties.join(", ")}</StyledSmallTitle>
        )}
      </TextWrapper>
      <Numbers>
        <NumberText>{item.price} €</NumberText>
        <SmallTitle>Cena</SmallTitle>
      </Numbers>
      <Numbers>
        <NumberText>{item.stock}</NumberText>
        <SmallTitle>Zaloga</SmallTitle>
      </Numbers>
      <Actions onDelete={() => onDeleteItem(item.id1)} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  margin-top: ${paddingSmall};
  display: flex;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 20px;
`;

const Numbers = styled.div``;

const NumberText = styled.div`
  font-weight: 600;
`;

const SmallTitle = styled.div`
  margin-top: 4px;
  color: ${colors.textDark};
  font-size: 14px;
`;

const StyledSmallTitle = styled.div`
  margin-top: 4px;
  color: ${colors.text};
  font-size: 14px;
`;

const Meal = styled.div`
  font-weight: 600;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 18px;

  &:not(:first-child) {
    margin-top: 4px;
  }
`;

export default Item;
