import styled from "styled-components";

import { colors } from "constants/variablesAdmin";

const InputAdmin = styled.input`
  outline: none;
  border: none;
  background-color: ${colors.backgroundExtraLight};
  border: 1px solid #dddddd;
  color: ${colors.text};
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  width: 100%;

  &::placeholder {
    color: ${colors.textExtraLight};
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    background-color: ${colors.primary};
    padding: 16px;
    border-radius: 8px;
  }

  &[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
`;

export default InputAdmin;
