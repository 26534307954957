import React from "react";
import styled from "styled-components";

import {
  device,
  contentWidth,
  xMarginMobile,
  colors,
} from "constants/variables";

const logo = require("../../images/femec-logotip-color.png");

const Cover = () => {
  return (
    <Wrapper>
      <Container>
        <Logo src={logo} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 12px 0;
  background-color: ${colors.black};
  color: ${colors.white};
`;

const Container = styled.div`
  max-width: ${contentWidth};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
  text-align: center;
`;

const Logo = styled.img`
  width: 130px;
`;

export default Cover;
