import moment from "moment";
import "moment/min/locales";

moment.locale("sl");

export const scrollToTop = () => {
  const html = document.querySelector("html");
  html.scrollTop = 0;
};

export const arrayToObject = (array, initialValue) =>
  Object.assign(...array.map((key) => ({ [key]: initialValue })));

export const isEmailValid = (email) => {
  const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
};

export const isPhoneNumberValid = (phone) => {
  const re = /^[-+()/\s]*([0-9][-+()/\s]*){9,}$/;
  return re.test(phone);
};

export const trimPhoneNumber = (phone) => {
  return phone
    .replace(/\s/g, "")
    .replace("-", "")
    .replace("+", "")
    .replace("(", "")
    .replace(")", "");
};

export const formatDate = (date, locale = "en") => {
  const format = locale === "sl" ? "D. M. YYYY" : "YYYY-MM-DD";
  return date.format(format);
};

export const parseDate = (dateStr, locale = "en") => {
  const format = locale === "sl" ? "D. M. YYYY" : "YYYY-MM-DD";
  return moment(dateStr, format);
};

export const getTodaysDate = (locale = "en") => formatDate(moment(), locale);

export const getCurrentWeekday = () => moment().weekday();

export const createListOfWorkDays = (startDate = moment()) => {
  const date = startDate.startOf("week");

  const workDays = [];
  for (let i = 0; i < 5; i++) {
    workDays.push({
      date: moment(date),
      label: date.format("dddd, D. M. YYYY")
    });
    date.add(1, "day");
  }

  return workDays;
};

export const isPastTodaysHourLimit = (date, time) =>
  date.startOf("day").isSame(moment().startOf("day")) &&
  moment() > moment(time, "HH:mm");
