import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  colors,
  paddingSmall,
  contentWidthWide
} from "constants/variablesAdmin";

import Routes from "./Routes";

const logo = require("../../images/femec-logotip-black.png");

const Order = ({ history, routes }) => {
  const location = useLocation();
  const path = location.pathname;

  const [user, setUser] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    const getCurrentAuthenticatedUser = async () => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser();
        setUser(attributes);
        setAuthenticated(true);
      } catch (error) {
        if (error !== "not authenticated") {
          console.log(error);
        }
      }
      setAuthenticating(false);
    };

    getCurrentAuthenticatedUser();
  }, [history]);

  const handleLogout = async () => {
    await Auth.signOut();
    setAuthenticated(false);
    history.push("/admin");
  };

  return (
    <Wrapper>
      <Container>
        <Navigation>
          <BrandWrapper>
            <Brand src={logo} />
          </BrandWrapper>
          {isAuthenticated && (
            <Links>
              <StyledLink
                to="/admin/orders"
                selected={path.split("/")[2] === "orders"}
              >
                Naročila
              </StyledLink>
              <StyledLink
                to="/admin/items"
                selected={path.split("/")[2] === "items"}
              >
                Ponudba
              </StyledLink>
            </Links>
          )}
          <User>
            {isAuthenticated && (
              <>
                <div>{user.email}</div>
                <Logout onClick={handleLogout}>Odjavi se</Logout>
              </>
            )}
          </User>
        </Navigation>
      </Container>
      <Routes
        childProps={{
          user,
          setUser,
          isAuthenticated,
          setAuthenticated,
          isAuthenticating,
          setAuthenticating
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${colors.backgroundDark};
`;

const Container = styled.div`
  padding: ${paddingSmall};
  background-color: ${colors.background};
  color: ${colors.text};
`;

const Navigation = styled.div`
  max-width: ${contentWidthWide};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BrandWrapper = styled.div`
  width: 240px;
`;

const Brand = styled.img`
  width: 80px;
`;

const Links = styled.div``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.text};
  padding: 12px;
  border-radius: 8px;

  &:not(:first-child) {
    margin-left: 24px;
  }

  &:hover {
    color: ${colors.primary};
    text-decoration: none;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.backgroundLight};
      border: 2px solid ${colors.primary};
      color: ${colors.primary};
    `};
`;

const User = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-between;
`;

const Logout = styled.div`
  cursor: pointer;
`;

export default withRouter(Order);
