export const colors = {
  primary: "#a28f65",
  secondary: "#968a32",
  primaryDark: "#877854",
  white: "#FFFFFF",
  black: "#000000",
  text: "#dddddd",
  textDark: "#999999",
  red: "#a33349",
  background: "#000000",
  backgroundLight: "#222222",
  backgroundExtraLight: "#262626",
  backgroundLight2: "#121212"
};

export const paddingSmall = "24px";
export const paddingMedium = "72px";
export const paddingLarge = "100px";
export const paddingExtraSmall = "16px";

export const xMarginMobile = "16px";

export const contentWidth = "950px";
export const contentWidthNarrow = "500px";
export const contentWidthWide = "1800px";
export const contentWidthAdmin = "950px";

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1280px",
  laptopL: "1440px",
  desktopS: "1920px",
  desktop: "2560px"
};

export const device = {
  mobile: `(max-width: ${size.laptop})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktopS: `(min-width: ${size.desktopS})`
};
