import React from "react";
import styled from "styled-components";

import {
  device,
  contentWidthNarrow,
  xMarginMobile,
  paddingMedium,
  paddingExtraSmall,
  colors
} from "constants/variables";

import Box from "shared/Box";
import Button from "shared/Button";
import Title from "shared/Title";

const check = require("images/check-circle.svg");

const OrderDone = ({ order, onResetOrder }) => {
  return (
    <Wrapper>
      <Container>
        <StyledBox>
          <CheckWrapper>
            <Check src={check} />
          </CheckWrapper>
          <Title>Naročilo št. {order.number} je oddano</Title>
          <Paragraph>
            Potrditveno e-mail sporočilo je bilo poslano na {order.email}
          </Paragraph>
        </StyledBox>
        <ButtonWrapper>
          <Button onClick={onResetOrder}>Novo naročilo</Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${paddingMedium} 0;
  background-color: ${colors.background};
  color: ${colors.text};
`;

const Container = styled.div`
  text-align: center;
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    max-width: ${contentWidthNarrow};
  }
`;

const StyledBox = styled(Box)`
  background-color: transparent;
  border: 2px solid ${colors.backgroundLight};

  @media ${device.mobile} {
    padding: ${paddingExtraSmall};
  }
`;

const CheckWrapper = styled.div`
  margin-bottom: 16px;
`;

const Check = styled.img`
  width: 50px;
`;

const Paragraph = styled.div`
  margin-top: 16px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${paddingMedium};
`;

export default OrderDone;
