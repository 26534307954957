export const locations = [
  {
    key: "Smelt",
    name: "Prehrana Smelt",
    description: "V Samopostrežni restavraciji Smelt",
    address: "Dunajska cesta 160, Ljubljana",
    googleMapsLink:
      "https://www.google.com/maps/place/FEMEC+-+GOSTINSTVO+Restavracija+Catering/@46.0827917,14.5117781,17z/data=!3m1!4b1!4m5!3m4!1s0x476532dd58435e11:0xfc2a522f6d4e3c12!8m2!3d46.082788!4d14.5139668",
    pickupTimeStart: "11.00",
    pickupTimeEnd: "13.30",
    discount: 0
  }
  // {
  //   key: "Klub Domus Medica",
  //   name: "Klub Domus Medica",
  //   description: "Ploščad pred vhodom v Klub Domus Medica",
  //   address: "Štukljeva cesta 48, Ljubljana",
  //   googleMapsLink:
  //     "https://www.google.com/maps/place/%C5%A0tukljeva+cesta+48,+1000+Ljubljana/@46.0841305,14.5127311,17.25z/data=!4m5!3m4!1s0x476532e782348987:0xc3efe986b2755420!8m2!3d46.0840871!4d14.5147959",
  //   pickupTimeStart: "12.00",
  //   pickupTimeEnd: "12.20",
  //   discount: 0
  // },
  // {
  //   key: "VO-KA SNAGA",
  //   name: "VO-KA SNAGA",
  //   description: "V lokalu VO-KA SNAGA",
  //   address: "Vodovodna cesta 90, Ljubljana",
  //   googleMapsLink:
  //     "https://www.google.com/maps/place/Restavracija+pri+Voki/@46.0753176,14.502028,17z/data=!3m1!4b1!4m5!3m4!1s0x476532c0dc3ce859:0x9200e0f1b796237a!8m2!3d46.0753139!4d14.5042167",
  //   pickupTimeStart: "10.30",
  //   pickupTimeEnd: "13.00",
  //   discount: 0
  // },
  // {
  //   key: "Siesta Bar",
  //   name: "Siesta Bar",
  //   description: "V Siesta Baru",
  //   address: "Brnčičeva ulica 13, Črnuče",
  //   googleMapsLink:
  //     "https://www.google.com/maps/place/Siesta+Bar/@46.0989609,14.5420887,15z/data=!4m5!3m4!1s0x0:0xa5c4d1f59e211426!8m2!3d46.0989609!4d14.5420887",
  //   pickupTimeStart: "10.30",
  //   pickupTimeEnd: "12.00",
  //   discount: 0
  // },
  // {
  //   key: "Babilon Pub",
  //   name: "Babilon Pub",
  //   description: "V Babilon Pubu",
  //   address: "Ljubljanska cesta 2A, Domžale",
  //   googleMapsLink:
  //     "https://www.google.com/maps/place/Babilon2+storitve+d.o.o.,+poslovna+enota+babilon+Pab/@46.1360875,14.5814129,15z/data=!4m5!3m4!1s0x0:0x364e788f1544eaf6!8m2!3d46.1360875!4d14.5814129",
  //   pickupTimeStart: "10.30",
  //   pickupTimeEnd: "12.00",
  //   discount: 0
  // }
];

export const getLocationDiscount = (_location) =>
  locations.find((location) => location.key === _location)?.discount || 0;
